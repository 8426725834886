import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';

const GoogleSheet = () => {
  const [data, setData] = useState([]);
  const [showOverlay, setShowOverlay] = useState(true); // State to control overlay visibility


  let productpic

  useEffect(() => {
    const fetchData = async () => {
      try {
        const SHEET_URL = 'https://docs.google.com/spreadsheets/d/1riz5CuLVk2LDml26E0_efV3elQFzYLU5ZrbLGt5wuzs/edit?usp=sharing#gid=data';
        const response = await axios.get(SHEET_URL);

        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(response.data, 'text/html');

        const table = htmlDoc.getElementsByTagName('table')[0];
        const rows = table.getElementsByTagName('tr');

        const sheetData = [];
        for (let i = 1; i < rows.length; i++) {
          const cells = rows[i].getElementsByTagName('td');
          const rowData = Array.from(cells).map(cell => cell.textContent);
          sheetData.push(rowData);
        }

        setData(sheetData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const extractSrcFromCellA = (cell) => {
    const imageTagRegex = /<img.*?src=['"](.*?)['"]/i;
    const matches = cell.match(imageTagRegex);
    if (matches && matches.length > 1) {
      return matches[1]; // Extracted src value
    }
    return null;
  };

  const getStyle = (cellIndex) => {
    let stylename = "Div-body";

    switch (cellIndex) {
      case 1:
        stylename = "Div-title";
        break;

      case 2:
      case 3:
        stylename = "Div-stock";
        break;

      case 4:
        stylename = "Div-price";
        break;

      case 5:
        stylename = "Div-info";
        break;

      default:
        break;
    }

    return stylename;
  };

  const handleOverlayClick = () => {
   setShowOverlay(false); // Remove the overlay when clicked
    document.body.style.overflow = 'auto';
 };

 useEffect(() => {
    if (showOverlay) {
      document.body.style.overflow = 'hidden'; // Disable scrolling on the page
    }
  }, [showOverlay]);



  useEffect(() => {
  const playVideo = () => {
    const video = document.getElementById('vid');
    video.play().catch((error) => {
      // Autoplay failed, handle error here
      console.error('Autoplay failed:', error);
    });
    setShowOverlay(false); // Remove the overlay when clicked
     document.body.style.overflow = 'auto';
    document.removeEventListener('click', playVideo); // Remove the event listener after the initial click
  };

  document.addEventListener('click', playVideo);

  return () => {
    document.removeEventListener('click', playVideo);
  };
}, []);
  return (
  <div className="Div-width">
  {showOverlay && (
        <div className="Overlay">
        <div className="Click-view">CLICK TO REVEAL TODAY'S DEAL</div>
           <div className="OverlayImg"></div>
        </div>
      )}

  <video id="vid" autoPlay={true} loop  playsInline  controls className="Video-player"><source src="/msi-stream-sq.mp4"  type='video/mp4'></source></video>
  <div className="Vid-space"></div>
    {data.map((row, index) => {
      if (row.every((cell) => cell === '')) {
        return null; // Skip rendering empty row
      }

      return (
        <div key={index}>
          {row.map((cell, cellIndex) => {
            let stylename = getStyle(cellIndex);
            if (cellIndex === 0) {
              const src = extractSrcFromCellA(cell);

              if (src) {
                productpic = (
                  <img src={src} key={cellIndex} alt="pic" className="Img-width" />
                );
                return <React.Fragment key={cellIndex}></React.Fragment>;
              }
            } else if (cellIndex === 1) {
              return (
                <div key={cellIndex}>
                  <div className={stylename} key={cellIndex}>
                    {cell}
                  </div>
                  {productpic}
                </div>
              );
            } else if (cellIndex === 3) {
              return (
                <React.Fragment key={cellIndex}>
                  <div className="Div-stock" key={cellIndex}>
                    {cell}
                  </div>
                  {row[5] && (
                    <div className="Div-stock">
                      <a href={row[5]} className="Div-spec">
                        Full Specs
                      </a>
                    </div>
                  )}
                </React.Fragment>
              );
            } else if (cellIndex === 5) {
              return <div className="Div-space" key={cellIndex}></div>;
            }

            return (
              <div className={stylename} key={cellIndex}>
                {cell}
              </div>
            );
          })}

          <div href="#" className="myButton">
            Quick Buy Deal
          </div>
        </div>
      );
    })}
  </div>
);
};

export default GoogleSheet;
